import "../../App.css";
import {
  Card,
  Carousel,
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import about_img from "../../images/abt.png";
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  faLocationArrow,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../images/CP logo.png";
import logoSmall from "../../images/CP logo.png";
import diningImg from "../../images/dining.jpg";
import takeawayImg from "../../images/take-away.jpg";
import deliveryImg from "../../images/delivery.jpg";
import eventsImg from "../../images/events.jpg";
import counterImg from "../../images/food-banner.png";
import {
  getItemBySubCategory,
  getRestaurantCategoryList,
  getSubCategoryByCategory,
} from "../../api/menuDetails";

function Home() {
  const [activeSection, setActiveSection] = useState("");

  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    const scrollPosition = window.pageYOffset;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 80;
      const sectionHeight = section.clientHeight;
      if (
        scrollPosition >= sectionTop - 50 &&
        scrollPosition < sectionTop + sectionHeight
      ) {
        setActiveSection(section.getAttribute("id"));
      }
    });
  };

  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [items, setItems] = useState();

  const selectedRestaurant = 30;
  const getRestaurantCategory = async () => {
    const response = await getRestaurantCategoryList(selectedRestaurant);
    setCategory(response?.data);
    setActiveTab(response?.data[0]?.id);
    return response?.data;
  };

  const getRestaurantsubCategory = async (id) => {
    const response = await getSubCategoryByCategory(id);
    setSubCategory(response?.data);
    setActiveSubCategoryTab(response?.data[0]?.id);
    return response?.data;
  };

  const getItems = async (id) => {
    const response = await getItemBySubCategory(id);
    setItems(response?.data);
    return response?.data;
  };

  useEffect(() => {
    getRestaurantCategory();
  }, []);

  console.log("category", category);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const counterElement = document.getElementById("counter");
      const oTop =
        counterElement?.getBoundingClientRect().top - window.innerHeight;

      if (!hasScrolled && window.scrollY > oTop) {
        document.querySelectorAll(".counter-value").forEach((element) => {
          const countTo = parseInt(element.getAttribute("data-count"), 10);
          const startCount = parseInt(element.innerText, 10);

          let currentCount = startCount;

          const animateCount = () => {
            const increment = countTo / 100; // Adjust for the smoothness of the count
            if (currentCount < countTo) {
              currentCount += increment;
              element.innerText = Math.floor(currentCount);
              requestAnimationFrame(animateCount);
            } else {
              element.innerText = countTo;
            }
          };

          animateCount();
        });
        setHasScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  const aboutRef = useRef(null);
  const menuRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const handleSmoothScroll = (e, sectionRef) => {
    e.preventDefault(); // Prevent the default anchor behavior
    if (sectionRef.current) {
      const targetPosition =
        sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: targetPosition - 100, // Scroll 100px above the div
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState(""); // Default active tab

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen is mobile (width less than 768px)
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check screen size on initial load

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handling tab change for both Tabs and Dropdown
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const [activeSubCategoryTab, setActiveSubCategoryTab] = useState("");

  const handleSubCategoryTabSelect = (tabKey) => {
    setActiveSubCategoryTab(tabKey);
  };

  useEffect(() => {
    getRestaurantsubCategory(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setItems([]);
    getItems(activeSubCategoryTab);
  }, [activeSubCategoryTab]);

  const arabicTabContent = (id) => {
    //
    return (
      subCategory && (
        <Tabs
          defaultActiveKey={subCategory[0]?.id}
          onSelect={handleSubCategoryTabSelect}
          activeKey={activeSubCategoryTab}
          id="uncontrolled-tab-example"
          className="justify-content-center mb-3"
        >
          {subCategory?.map((el) => (
            <Tab
              eventKey={el.id}
              title={el.name}
              style={{ minHeight: "300px" }}
            >
              {/* Item name images */}
              <div className="row">
                {items?.map((data) => (
                  <div className="col-md-4 mb-4">
                    <Card>
                      <img
                        src={process.env.REACT_APP_baseUrl + data?.itemImgUrl}
                        alt=""
                      />
                      <div className="d-flex justify-content-between p-3">
                        <h5 className="title">{data?.name}</h5>
                        <div className="price">{data?.price}</div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </Tab>
          ))}
        </Tabs>
      )
    );
  };

  return (
    <div classNameName="App">
      <header>
        <Navbar
          expand="lg"
          fixed={`top ${isShrunk ? "reduced-nav" : ""}`}
          className="header-top"
        >
          <Container>
            <Navbar.Brand href="#home">
              {isShrunk ? (
                <img src={logoSmall} alt="" />
              ) : (
                <img src={logo} alt="" />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="main-menu">
                <Nav.Link
                  className={`${activeSection === "home" ? "active-menu" : ""}`}
                  href="#home"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  href="#!"
                  className={`${
                    activeSection === "about" ? "active-menu" : ""
                  }`}
                  onClick={(e) => handleSmoothScroll(e, aboutRef)}
                >
                  About Us
                </Nav.Link>

                <Nav.Link
                  href="#!"
                  className={`${
                    activeSection === "services" ? "active-menu" : ""
                  }`}
                  onClick={(e) => handleSmoothScroll(e, servicesRef)}
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  className={`${activeSection === "menu" ? "active-menu" : ""}`}
                  href="#!"
                  onClick={(e) => handleSmoothScroll(e, menuRef)}
                >
                  Menu
                </Nav.Link>
                <Nav.Link
                  className={`${
                    activeSection === "contact-us" ? "active-menu" : ""
                  }`}
                  href="#!"
                  onClick={(e) => handleSmoothScroll(e, contactRef)}
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link href="tel:1234567890" className={`phone-no-item`}>
                  <FontAwesomeIcon icon={faPhone} /> 23289777
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <section id="home" className="main-banner">
        <Carousel>
          <Carousel.Item>
            <Carousel.Caption>
              <Container>
                <h3>
                  Taste the Magic of <br />
                  Authentic Chinese Cuisine!
                </h3>

                <p>Fresh Ingredients, Timeless Recipes!</p>
                <a href="#contact-us" class="banner-btn">
                  Contact Us
                </a>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption>
              <Container>
                <h3>
                  Delight in the Richness of <br />
                  Chinese Traditions
                </h3>
                <p>"From Our Kitchen to Your Heart!"</p>
                <a href="#contact-us" class="banner-btn">
                  Contact Us
                </a>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption>
              <Container>
                <h3>
                  Experience the Art of <br />
                  Chinese Cooking!
                </h3>
                <p>"Bold, Fresh, and Unforgettably Delicious!"</p>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      <section id="about" ref={aboutRef} className="py-100">
        <div className="container">
          <Row className="about-content">
            <Col lg={6}>
              <img src={about_img} alt="" />
            </Col>
            <Col lg={6}>
              <h2 className="text-center mb-5">Who we are</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dolores, molestias accusantium vel enim aperiam inventore id
                nulla dicta libero nihil beatae maxime adipisci corrupti, quae
                optio dignissimos quod! Sint, delectus.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dolores, molestias accusantium vel enim aperiam inventore id
                nulla dicta libero nihil beatae maxime adipisci corrupti, quae
                optio dignissimos quod! Sint, delectus. Lorem ipsum dolor sit
                amet consectetur adipisicing elit. Dolores, molestias
                accusantium vel enim aperiam inventore id nulla dicta libero
                nihil beatae maxime adipisci corrupti, quae optio dignissimos
                quod! Sint, delectus.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dolores, molestias accusantium vel enim aperiam inventore id
                nulla dicta libero nihil beatae maxime adipisci corrupti, quae
                optio dignissimos quod! Sint, delectus.
              </p>
              <div className="row" id="counter">
                <Col md={4} sm={6}>
                  <div>
                    <h4>
                      <span className="counter-value" data-count="1500">
                        0
                      </span>
                      +
                    </h4>
                    <h6>Happy Customers</h6>
                  </div>
                </Col>
                <Col md={4} sm={6}>
                  <div>
                    <h4>
                      <span className="counter-value" data-count="20">
                        0
                      </span>
                      k+
                    </h4>
                    <h6>Food Delivered</h6>
                  </div>
                </Col>
                <Col md={4} sm={6}>
                  <div>
                    <h4>
                      <span className="counter-value" data-count="15">
                        0
                      </span>
                      +
                    </h4>
                    <h6>Years Experience</h6>
                  </div>
                </Col>
                {/* <Col md={3} sm={6}>
                  <div>
                    <h4>
                      <span className="counter-value" data-count="500">
                        0
                      </span>
                      +
                    </h4>
                    <h6>Food Items</h6>
                  </div>
                </Col> */}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section id="services" ref={servicesRef} className="text-center py-100">
        <div className="container">
          <h2 className="mb-5">Our Services</h2>
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                {/* <FontAwesomeIcon icon={faUtensils} className="fontIcon" /> */}
                <img src={diningImg} alt="" />
                <h5 className="serviceHead">Dining</h5>
                <p>
                  Our dining service offers a refined experience with a diverse
                  menu designed to suit any occasion. Enjoy exceptional cuisine
                  and service in a welcoming atmosphere.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                {/* <FontAwesomeIcon icon={faBagShopping} className="fontIcon" /> */}
                <img src={takeawayImg} alt="" />
                <h5 className="serviceHead">Take Away</h5>
                <p>
                  Our take-away service allows you to enjoy our delicious foods
                  at home. Convenient and flavorful, it's perfect for any time
                  you want a restaurant-quality meal without the dining in
                  experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                {/* <FontAwesomeIcon icon={faTruck} className="fontIcon" /> */}
                <img src={deliveryImg} alt="" />
                <h5 className="serviceHead">Home Delivery</h5>
                <p>
                  Our home delivery service brings our delectable dishes
                  straight to your door. With fast and reliable delivery, you
                  can savor our diverse menu without stepping out.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                {/* <FontAwesomeIcon icon={faCakeCandles} className="fontIcon" /> */}
                <img src={eventsImg} alt="" />
                <h5 className="serviceHead">Accommodate all events</h5>
                <p>
                  We accommodate all events, offering a versatile space and
                  personalized service to meet your needs. our team ensures
                  every detail is tailored to create a memorable experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="menu" ref={menuRef} className="py-100">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Popular Items</h2>
            <div className="tab-container mobile-display">
              {isMobile
                ? category && (
                    <>
                      <Dropdown onSelect={handleTabSelect}>
                        <Dropdown.Toggle variant="secondary" id="menu-dropdown">
                          {
                            category?.find(
                              (el) =>
                                parseInt(el.id, 10) === parseInt(activeTab, 10)
                            )?.name
                          }
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-100">
                          {category?.map((item) => (
                            <Dropdown.Item eventKey={item.id}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div>{arabicTabContent()}</div>
                    </>
                  )
                : category && (
                    <Tabs
                      defaultActiveKey={category[0]?.id}
                      onSelect={handleTabSelect}
                      activeKey={activeTab}
                      id="uncontrolled-tab-example"
                      className="mb-2 flex-column nav-category-menu"
                    >
                      {category?.map((el) => (
                        <Tab eventKey={el.id} title={el.name}>
                          {/* Item subcategory name */}
                          {arabicTabContent(el.id)}
                        </Tab>
                      ))}
                    </Tabs>
                  )}
            </div>
          </div>
        </div>
      </section>

      <section
        id="counter-section"
        className="text-center py-100 counter-content"
        style={{ backgroundImage: `url(${counterImg})` }}
      >
        <Container>
          <Row>
            <Col md={7}>
              <h3>Always Hot Food Delivered At Your Door Step</h3>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="contact-us" ref={contactRef}>
        <div className="contact-map">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d122083.2109437007!2d54.010483428527365!3d17.018747516602243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3dd3dffa8b2b4cc3%3A0x299b1f86e6d3a1d2!2sChina%20palace%20Restaurant!5e0!3m2!1sen!2sin!4v1734024744380!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="contact-data-container">
          <div className="contact-data">
            <h4>Contact Us</h4>
            <ul className="content-contact">
              <li>
                <FontAwesomeIcon
                  icon={faLocationArrow}
                  className="fontAddressIcon"
                />
                <p>
                  Silver diamond restaurant Salalah Oman <br />
                  Near New Salalah Health Center
                </p>
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="fontAddressIcon"
                />
                <p>business@silverdiamond.com</p>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} className="fontAddressIcon" />
                23289777
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="fontAddressIcon footer-whatsapp"
                />
                93543747
              </li>
            </ul>
          </div>
        </div>
      </section>
      <footer>
        <div className="container foot-content">
          <div className="row row justify-content-between">
            <div className="col-md-3 foot-about-content">
              <h4>About Us</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt
                blanditiis soluta, recusandae at voluptate nihil deserunt sit
                fugit repudiandae, animi cumque quia.
              </p>
            </div>

            <div className="col-md-3 subscribe-section">
              <h4>Services</h4>
              <div className="">
                <ul className="navbar-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Dining
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      Take Away
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services">
                      Home Delivery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#menu">
                      Accommodate all events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 subscribe-section">
              <h4>Quick Links</h4>
              <div className="">
                <ul className="navbar-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#menu">
                      Menu
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact-us">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 insta-section">
              <h4>Stay Connected</h4>
              <div className="row">
                <ul className="navbar-nav-footer social-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="fontAddressIcon"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center copyright_section">
          &copy; 2024. All rights reserved
        </div>
      </footer>
    </div>
  );
}

export default Home;
