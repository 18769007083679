import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "./Pages/Menu";
import MenuItems from "./Pages/MenuItems";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/menu"} element={<Menu />} />
        <Route path={`/menu-items/:id`} element={<MenuItems />} />
      </Routes>
    </Router>
  );
}

export default App;
